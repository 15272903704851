import { BASE_URL } from "../../EnvVariables";
import {
  addBusinessScenario,
  listBusinessScenario,
  getBusinessScenarioCount,
  setBusinessScenarioPage,
  setBusinessScenarioSize,
  updateBusinessScenario,
  setSelectedBusinessScenario
} from "../../actions/businessScenario/actions";
import {
  startLoading,
  stopLoading,
  displayMessage,
  listSchedule
} from "../../actions/common/actions";
import { token } from "../common/auth";

export const addBusinessScenarioRequest = (
  name,
  description = "",
  applicationId
) => async dispatch => {
  try {
    dispatch(startLoading());
    const body = {
      name: name,
      description: description,
      applicationId:applicationId
    };
    const response = await fetch(`${BASE_URL}/api/businessScenario/save`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token()
      },
      method: "post",
      body: JSON.stringify(body)
    });
    const businessScenario = await response.json();
    if (response.status === 201) {
      dispatch(addBusinessScenario(businessScenario));
      dispatch(listBusinessScenarioRequest(0,10,applicationId));
      dispatch(displayMessage("Business scenario added successfully."));
    } else {
      dispatch(
        displayMessage(
          "Could not add the business scenario. Try the operation again."
        )
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not add the business scenario. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const getBusinessScenarioCountRequest = (
  applicationId
) => async dispatch => {
  dispatch(startLoading());
  console.log("Get businessScenario count request");
  try {
    const businessScenarioCountResponse = await fetch(
      `${BASE_URL}/api/businessScenario/count?applicationId=${applicationId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "get"
      }
    );
    const businessScenarioCount = await businessScenarioCountResponse.json();
    dispatch(getBusinessScenarioCount(businessScenarioCount.count));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not fetch business scenario count. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const getSchedulerListRequest = (
  businessScenarioId
) => async dispatch => {
  dispatch(startLoading());
  try {
    const scheduleListResponse = await fetch(
      `${BASE_URL}/api/schedule/listOfBusinessScenarioScheduled?businessScenarioId=${businessScenarioId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "get"
      }
    );
    const schedule = await scheduleListResponse.json();
    dispatch(listSchedule(schedule));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not fetch schedule task list. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};


export const deleteScheduleRequest = (
  selectedScheduleId = 0,
  businessScenarioId
) => async dispatch => {
  try {

    const response = await fetch(`${BASE_URL}/api/schedule/delete/${selectedScheduleId}`, {
      headers: {
        "accept": "*/*",
        Authorization: "Bearer " + token()
      },
      method: 'post'
    });

    if (response.status === 200) {
      dispatch(getSchedulerListRequest(businessScenarioId));
      dispatch(displayMessage("File deleted successfully."));
    } else {
      dispatch(
        displayMessage("Could not delete the File. Try the operation again.")
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage("Could not delete the File. Try the operation again")
    );
    dispatch(stopLoading());
  }
};


export const listBusinessScenarioRequest = (
  page = 0,
  size = 10,
  applicationId
) => async dispatch => {
  try {
    dispatch(startLoading());
    const businessScenarioCountResponse = await fetch(
      `${BASE_URL}/api/businessScenario/count?applicationId=${applicationId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "get"
      }
    );
    const businessScenarioCount = await businessScenarioCountResponse.json();
    dispatch(getBusinessScenarioCount(businessScenarioCount.count));
    dispatch(setBusinessScenarioPage(page));
    dispatch(setBusinessScenarioSize(size));
    const response = await fetch(
      `${BASE_URL}/api/businessScenario/list?page=${page}&size=${size}&applicationId=${applicationId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "get"
      }
    );
    const businessScenarios = await response.json();
   if (businessScenarios.length > 0) {
   const businessScenarioResponse = await fetch(
    `${BASE_URL}/api/businessScenario/${businessScenarios[0].id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token()
      },
      method: "get"
    }
  );
  const businessScenarioObj = await businessScenarioResponse.json();
  dispatch(setSelectedBusinessScenario(businessScenarioObj));
}
    dispatch(listBusinessScenario(businessScenarios));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not fetch the business scenarios. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const updateBusinessScenarioRequest = (
  id = 0,
  name = "",
  description = "",
  featureIds = [],
  applicationId
) => async dispatch => {
  try {
    const body = {
      id: id,
      name: name,
      description: description,
      featureIds: featureIds,
      applicationId:applicationId
    };
    const response = await fetch(`${BASE_URL}/api/businessScenario/save`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token()
      },
      method: "post",
      body: JSON.stringify(body)
    });
    const businessScenario = await response.json();
    if (response.status === 202) {
      dispatch(displayMessage("Business Scenario updated successfully."));
      dispatch(updateBusinessScenario(businessScenario));
      dispatch(setSelectedBusinessScenario(businessScenario));
    } else {
      dispatch(
        displayMessage(
          "Could not update the business scenario. Try the operation again."
        )
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not update the business scenario. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};

export const setSelectedBusinessScenarioRequest = businessScenario => async dispatch => {
  dispatch(startLoading());
  console.log("Set selected business scenario request");
  try {
    const businessScenarioResponse = await fetch(
      `${BASE_URL}/api/businessScenario/${businessScenario.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token()
        },
        method: "get"
      }
    );
    const businessScenarioObj = await businessScenarioResponse.json();
    dispatch(setSelectedBusinessScenario(businessScenarioObj));
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not fetch business scenario count. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};


export const deleteBusinessScenarioRequest = (
  id = 0,
  applicationId
) => async dispatch => {
  try {
    const body = {
      id: id
    };
    const response = await fetch(`${BASE_URL}/api/businessScenario/delete`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token()
      },
      method: "post",
      body: JSON.stringify(body)
    });
    
    if (response.status === 200) {
      dispatch(displayMessage("Business Scenario deleted successfully."));
      dispatch(listBusinessScenarioRequest(0,10,applicationId));
    } else if (response.status === 412) {
      dispatch(displayMessage("Schedules need to be deleted before deleting Business Scenario"));
    } else {
      dispatch(
        displayMessage(
          "Could not delete the business scenario. Try the operation again."
        )
      );
    }
    dispatch(stopLoading());
  } catch (e) {
    console.log(e)
    dispatch(
      displayMessage(
        "Could not delete the business scenario. Try the operation again"
      )
    );
    dispatch(stopLoading());
  }
};


export const setBusinessScenarioSearchTxt = (
  businessScenarios,
  page,
  size,
  count
) => async dispatch => {
  dispatch(getBusinessScenarioCount(count));
  dispatch(setBusinessScenarioPage(page));
  dispatch(setBusinessScenarioSize(size));
  dispatch(listBusinessScenario(businessScenarios));
};
