import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { connect } from "react-redux";
import BarChartPlan from './BarChartPlan'
import { map } from "lodash";
import BarChartPlanFtype from './BarChartPlanFtype'
import {listBusinessScenarioRequest} from "../../thunks/businessScenario/thunks";
import {getFeatures,getFeatureCount} from "../../selectors/feature/selector";
import {getBusinessScenarios} from "../../selectors/businessScenario/selector";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { getSelectedApplication } from "../../selectors/application/selector";



const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    marginLeft: '50px',
    marginRight: '20px',
  },
  details: {
    textAlign: 'center',
    border: '1px solid lightgrey',

  },
  box: {
    marginTop: "60px",
    marginLeft: '50px',
    marginRight: '20px',
    width: "flex",
  },
  header: {
    float: 'left',
    paddingBottom: '106px'
  },
  chart: {
    flexGrow: 1,
    padding: '20px',
  }
}));




const PlanDashboard = ({
  features = [],
  loadBusinessScenarioFromServer,
  release = [],
  count,
  total = 0,
  RelTCCount = 0,
  relCount=0,
  pages = 0,
  size = 10,
  applicationId
}) => {
  useEffect(() => {
    loadBusinessScenarioFromServer(pages, size, applicationId);
  }, [loadBusinessScenarioFromServer, pages, size, applicationId]);


  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const columns = [
    { id: 'Feature', label: 'Feature', minWidth: 170 },
    {
      id: 'ScenarioCount',
      label: 'Test Case Count',
      minWidth: 170,
      align: 'center',
      format: (value) => value,
    },
  ];

  const columnsRel = [
    { id: 'Release', label: 'Release', minWidth: 170 },
    {
      id: 'FeatureCount',
      label: 'Feature Count',
      minWidth: 170,
      align: 'center',
      format: (value) => value,
    },
  ];

  const columnsRelScn = [
    { id: 'Release', label: 'Release', minWidth: 170 },
    {
      id: 'FeatureCount',
      label: 'Test Case Count',
      minWidth: 170,
      align: 'center',
      format: (value) => value,
    },
  ];


  function createData(Feature, ScenarioCount) {
    return { Feature, ScenarioCount };
  }

  map(features, (feature) => (
    total += feature.scenarioCount
  ))

  map(release, (rel) => (
    relCount = release.length
  ))


  // const rowsRelScn =
  //   map(release, (rel) => (
  //     map(rel.features, (feature) => (
  //       // console.log( 'features ... ', feature.name),
  //       // console.log( 'count ... ', feature.scenarioCount),
  //       totalRelCount += feature.scenarioCount,
  //       // console.log( 'Release total ... ', totalRelCount),
  //       createData(rel.name, totalRelCount)
  //     ))
  //   ))

    const rowsRel =
    map(release, (rel) => (
      createData(rel.name, rel.features.length)
    ))

    function FeatureTypeCount() {
      const res = release.filter(function (feature) { return feature.name == "Release_1" });
      console.log('Rel type is..', res)

      map(res, (re, index) => (
        console.log('Index is', re.features.length),
        console.log('RR',RelTCCount += re.features[index].scenarioCount)
      ))
      console.log('TotlRC Count', RelTCCount)
      return res
    }

    FeatureTypeCount()

  const rows =
    map(features, (feature) => (
      createData(feature.name, feature.scenarioCount)
    ))

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>

      <Grid item xs={12} md={6} lg={3}>
          <Card>
            <CardHeader className={classes.header} style={{  backgroundImage: 'linear-gradient(195deg,#ec407a,#d81b60)', width: '60px' }} />
            <div className={classes.details}>
              <CardContent>
                <Typography variant="body2" color="inherit">
                  Total Feature Count
                </Typography>
                <Typography variant="h3" color="textSecondary" component="p" style={{ marginTop: '4px' }}>
                {count}
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <CardHeader className={classes.header} style={{  backgroundImage: 'linear-gradient(195deg,#ec407a,#d81b60)', width: '60px' }} />
            <div className={classes.details}>
              <CardContent>
              <Typography variant="body2" color="inherit" component="p">
                  Total Test Case Count
                </Typography>
                <Typography variant="h3" color="textSecondary" component="p" style={{ marginTop: '4px' }}>
                  {total}
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card >
            <CardHeader className={classes.header} style={{  backgroundImage: 'linear-gradient(195deg,#ec407a,#d81b60)', width: '60px' }} />
            <div className={classes.details}>
              <CardContent>
              <Typography variant="body2" color="inherit" component="p">
                  Average Scenario Per Feature
                </Typography>
                <Typography variant="h3" color="textSecondary" component="p" style={{ marginTop: '4px' }}>
                 {(total/count).toFixed(2)}
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <CardHeader className={classes.header} style={{  backgroundImage: 'linear-gradient(195deg,#ec407a,#d81b60)', width: '60px' }} />
            <div className={classes.details}>
              <CardContent>
                <Typography variant="body2" color="inherit" component="p">
                  Release count
                </Typography>
                <Typography variant="h3" color="textSecondary" component="p" style={{ marginTop: '4px' }}>
                 {relCount}
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Grid>

        <Grid container>
          <Grid item xs style={{ paddingTop: '50px' , paddingLeft: '10px'}} >
            <Typography variant="h6" color="inherit" style={{ paddingLeft: '30px', paddingBottom: '10px' }}>
              Test Case Count Per Feature
            </Typography>
            <Paper style={{ width: '400px' }}>
              <TableContainer style={{ maxHeight: '440px' }}>
                <Table stickyHeader aria-label="sticky table" >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ backgroundColor: '#3f51b5', color: 'white' }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number' ? column.format(value) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>

          <Grid item xs style={{ paddingTop: '50px', paddingLeft: '10px' }} >
            <Typography variant="h6" color="inherit" style={{ paddingLeft: '30px', paddingBottom: '10px' }}>
            Feature Count Per Release
            </Typography>
            <Paper style={{ width: '400px' }}>
              <TableContainer style={{ maxHeight: '440px' }}>
                <Table stickyHeader aria-label="sticky table" >
                  <TableHead>
                    <TableRow>
                      {columnsRel.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ backgroundColor: '#3f51b5', color: 'white' }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsRel.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number' ? column.format(value) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>

          <Grid item xs style={{ paddingTop: '50px', paddingLeft: '10px' }} >
          <BarChartPlanFtype className={classes.chart} />
          <div >
              <Typography variant="h6" color="textSecondary" align="center">
              Feature count by Test type
              </Typography>
              </div>
            {/* <Typography variant="h6" color="inherit" style={{ paddingLeft: '30px', paddingBottom: '10px' }}>
            Test Case Count Per Release
            </Typography>
            <Paper style={{ width: '400px' }}>
              <TableContainer style={{ maxHeight: '440px' }}>
                <Table stickyHeader aria-label="sticky table" >
                  <TableHead>
                    <TableRow>
                      {columnsRelScn.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ backgroundColor: '#3f51b5', color: 'white' }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsRel.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number' ? column.format(value) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper> */}
          </Grid>

          {/* <Grid item xs={6} style={{paddingTop: '30px', paddingLeft: '10px'}} >
          <BarChartPlan className={classes.chart} />
          <div >
              <Typography variant="h6" color="textSecondary" align="center">
              Feature count per release
              </Typography>
              </div>
          </Grid>

          <Grid item xs={6} style={{paddingTop: '30px', paddingLeft: '10px'}} >
          <BarChartPlanFtype className={classes.chart} />
          <div >
              <Typography variant="h6" color="textSecondary" align="center">
              Feature count by Test type
              </Typography>
              </div>
          </Grid> */}

        </Grid>

      </Grid>
    </div>

  );
};

const mapStateToProps = state => ({
  features: getFeatures(state),
  count: getFeatureCount(state),
  release: getBusinessScenarios(state),
  applicationId: getSelectedApplication(state).id


});

const mapDispatchToProps = dispatch => ({
  loadBusinessScenarioFromServer: (pages, size, applicationId) => {
    dispatch(listBusinessScenarioRequest(pages, size, applicationId));
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(PlanDashboard);